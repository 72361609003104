<template>
  <section class="px-3 ml-3">
        <navbar-admin icon="icon-archive" />
        <titulo-divisor titulo="Ordenes de compra">
            <div class="row d-middle-center">
                <div class="col auto">
                    <input-search v-model="query" :search="beforeSearch" auto-search placeholder="Buscar bodega" size="small" />
                </div>
                <div class="col-auto px-1">
                    <el-tooltip content="Dashboard" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d cr-pointer">
                            <i class="icon-finance text-white" @click="goDashboard()" />
                        </button>
                    </el-tooltip>
                </div>
            </div>
        </titulo-divisor>

        <div class="mt-5 bg-f5 p-2 py-3">
            <div class="row">
                <div class="col-6 my-auto">
                    <div class="row">
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Nombre</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Pais</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Ubicación</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Dirección</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 my-auto">
                    <div class="row">
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Cantidad materiales</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Valor materiales</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Fecha última salida</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Fecha última entrada</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-6 my-auto">
                    <div class="row">
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-muted text-center">{{ purchaseOrders.bodega && purchaseOrders.bodega.nombre }}</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ purchaseOrders.bodega && purchaseOrders.bodega.pais }}</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ purchaseOrders.bodega && purchaseOrders.bodega.ubicacion }}</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ purchaseOrders.bodega && purchaseOrders.bodega.direccion }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 my-auto">
                    <div class="row">
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ purchaseOrders.bodega && purchaseOrders.bodega.cantidad_materiales }}</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ formatoMoneda(purchaseOrders.bodega && purchaseOrders.bodega.valor_materiales) }}</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ purchaseOrders.bodega && purchaseOrders.bodega.fecha_ultima_entrada | helper-fecha('DD MMM YYYY') }}</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ purchaseOrders.bodega && purchaseOrders.bodega.fecha_ultima_salida | helper-fecha('DD MMM YYYY') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <tabla-general :mostrarBuscador="false" alturaTabla="500px" :data="purchaseOrders.ordenes">
            <el-table-column align="center" label="Orden" prop="referencia">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 text-center tres-puntos">{{ scope.row.id }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Proyecto" prop="nombre" width="400">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos f-600">
                        {{ scope.row.proyecto }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Proveedor" prop="tipo">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.proveedor }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipos" prop="um">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.tipo }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cliente" prop="valor">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.cliente }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="Valor" prop="cantidad">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 text-center">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="Pago" prop="planes">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 text-center">{{ typeof scope.row.pago === 'number' ? formatoMoneda(scope.row.pago) : scope.row.pago }}</p>
                </template>
            </el-table-column>
        </tabla-general>

    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Ordenes',
    data() {
        return {
            query: ''
        }
    },
    computed: {
        ...mapGetters({
            purchaseOrders: 'bodega/bodega/purchaseOrders'
        })
    },
    async created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'bodega.main',
            'bodega.materiales',
            'bodega.ordenes-compra'
        ]);
        this.Action_warehouse_purchase_orders({ id: this.$route.params.id_bodega, buscar: '' })
    },
    methods: {
        ...mapActions({
            Action_warehouse_purchase_orders: 'bodega/bodega/Action_warehouse_purchase_orders'
        }),
        async beforeSearch(){
            await this.Action_warehouse_purchase_orders({ id: this.$route.params.id_bodega, buscar: this.query })
        },
        goDashboard(){
            this.$router.push({ name: 'bodega.dashboard'})
        },
    },
}
</script>

<style lang="scss" scoped>

</style>